var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pi-hole-stats-wrapper" }, [
    _vm.status
      ? _c("div", { staticClass: "status" }, [
          _c("span", { staticClass: "status-lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.pi-hole.status-heading")) + ":"),
          ]),
          _c(
            "span",
            { class: `status-val ${_vm.getStatusColor(_vm.status)}` },
            [_vm._v(_vm._s(_vm._f("capitalize")(_vm.status)))]
          ),
        ])
      : _vm._e(),
    _c("p", { staticClass: "block-pie", attrs: { id: _vm.chartId } }),
    _vm.dataTable
      ? _c(
          "div",
          { staticClass: "data-table" },
          _vm._l(_vm.dataTable, function (row, inx) {
            return _c("div", { key: inx, staticClass: "data-table-row" }, [
              _c("p", { staticClass: "row-label" }, [_vm._v(_vm._s(row.lbl))]),
              _c("p", { staticClass: "row-value" }, [_vm._v(_vm._s(row.val))]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }